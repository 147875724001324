<template>

</template>

<script setup lang="ts">

</script>


<script lang="ts">

export default {
  name: "Logo",
}
</script>

<style scoped>

</style>
